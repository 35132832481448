/**
 * components.js
 *
 * Import the JS for your components here.
 *
 */
import './Accordion/Accordion';
import './BackToTop/BackToTop';
import './SocialShare/SocialShare';
import './Buttons/stopDisabledLinksFiring';
import './Tables/TableWrapper';
import './Modal/Modal';
import './Slider/Slider';
import './AlgoliaPagination/AlgoliaPagination';
import ComponentInit from '../js/utils/ComponentInit';
import VideoEmbed from './VideoEmbed/VideoEmbed';
import DonationList from './DonationList/DonationList';
import ThankYou from './ThankYou/ThankYou';

import './DonationDisplayToggle/donation-display-toggle';

// Format:
// data-component-name: ComponentFunction
// we shall order these in page load critical first
const componentList = {
    VideoEmbed,
    'donation-list-app': DonationList,
    'thank-you-app': ThankYou
};

export default ComponentInit(componentList);
