import React, { FunctionComponent, useState } from 'react';
// import Button from '3-components/Buttons/Button';
import Icon from '3-components/Icon/Icon';

export interface SocialShareProps {
    inheritColor?: boolean;
    intro?: boolean,
    facebookLink?: string,
    twitterLink?: string,
    emailLink?: string,
    copyLink?: string,
    additionalClasses?: string
}

const SocialShare: FunctionComponent<SocialShareProps> = props => {
    const {
        inheritColor = false,
        intro,
        facebookLink,
        twitterLink,
        emailLink,
        copyLink,
        additionalClasses
    } = props;

    // const mystarUrl = `${window.location.host}/view?star=${id}`;
    // const facebookShareUrl = `https://www.facebook.com/sharer/sharer.php?u=${mystarUrl}`;
    // const twitterShareUrl = `https://twitter.com/intent/tweet?text=Let's%20make%20the%20world%20brighter%20together&url=${mystarUrl}`;
    // // `http://twitter.com/share?text=Let's make the world brighter together&url=${mystarUrl}`;
    // const emailShareUrl = 'mailto:?subject=Let\'s%20make%20the%20world%20brighter%20together&body=I%20donated%20and%20you%20should%20too.'
    // + `%20Check%20my%20stars:%20${mystarUrl}`;

    const [showCopied, setShowCopied] = useState(false);

    const copyUrlHandle = () => {
        navigator.clipboard.writeText(copyLink ? copyLink : '');
        setShowCopied(true);
        setTimeout(() => {
            setShowCopied(false);
        }, 1000);
    };

    return (
        <div className={`social-share ${additionalClasses} ${inheritColor ? 'inherit-color' : ''}`}>
            {intro && (
                <div className="social-share-intro">Share:</div>
            )}
            {facebookLink && (
                <a href={facebookLink} target="_blank" rel="noopener norefferer noreferrer"><span className="sr-only">facebook</span><Icon icon="facebook" /></a>
            )}
            {twitterLink && (
                <a href={twitterLink} target="_blank" rel="noopener norefferer noreferrer"><span className="sr-only">twitter</span><Icon icon="twitter" /></a>
            )}
            {emailLink && (
                <a href={emailLink} target="_blank" rel="noopener norefferer noreferrer"><span className="sr-only">email</span><Icon icon="mail" /></a>
            )}
            {copyLink && (
                <>
                    <button type="button" className="copy-page-url-btn" onClick={copyUrlHandle}>
                        <Icon icon="link" /><span>Copy link</span>
                    </button>
                    {showCopied && (
                        <h5 className="copied-link-text visible">Link copied!</h5>
                    )}
                </>
            )}
        </div>

    );
};

export default SocialShare;
