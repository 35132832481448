import React, { FunctionComponent } from 'react';
import TimeSinceNow from '3-components/Utils/DateUtils';
import { formatToAUCurrency, formatHugeAmountToAUCurrency } from '3-components/Utils/NumberUtils';
import { DonationItem } from '../../@types/donationItem';

const DonationListItemDonation: FunctionComponent<DonationItem> = props => {
    const {
        name,
        dedication,
        state,
        total,
        timestamp
    } = props;

    const donateTime = TimeSinceNow(timestamp);

    let donateAmount = formatToAUCurrency(total);
    if (total / 1000000000 >= 1) {
        donateAmount = formatHugeAmountToAUCurrency(total);
    }
    const donateAmountDisplay = `${donateAmount} donation`;

    return (
        <li className="donation-list-item-donation">
            <img className="donation-list-image" src="/assets/images/Star.png" alt="star" />
            <div className="donation-list-info">
                <div className="donation-list-intro">
                    <h3 className="intro-title">{name} {state && (<span className="location-title text-thin">{state}</span>)}</h3>
                    <p className="intro-short-desc">{dedication}</p>
                </div>
                <div className="donation-amount">
                    <span className="donation-total">
                        {donateAmountDisplay}
                    </span>
                    <span>{donateTime}</span>
                </div>
            </div>
        </li>
    );
};

export default DonationListItemDonation;
