import React from 'react';
import kebabCase from 'lodash.kebabcase';
import { ClickOrPressEvent } from 'types/mouseEvents';

import { ReactComponent as Adjust } from 'assets/icons/adjust.svg';
import { ReactComponent as ArrowDownCircle } from 'assets/icons/arrow-down-circle.svg';
import { ReactComponent as ArrowDown } from 'assets/icons/arrow-down.svg';
import { ReactComponent as ArrowLeftCircle } from 'assets/icons/arrow-left-circle.svg';
import { ReactComponent as ArrowLeft } from 'assets/icons/arrow-left.svg';
import { ReactComponent as ArrowRightCircle } from 'assets/icons/arrow-right-circle.svg';
import { ReactComponent as ArrowRight } from 'assets/icons/arrow-right.svg';
import { ReactComponent as ArrowUpCircle } from 'assets/icons/arrow-up-circle.svg';
import { ReactComponent as ArrowUp } from 'assets/icons/arrow-up.svg';
import { ReactComponent as At } from 'assets/icons/at.svg';
import { ReactComponent as BankCard } from 'assets/icons/bank-card.svg';
import { ReactComponent as Bank } from 'assets/icons/bank.svg';
import { ReactComponent as Banknote } from 'assets/icons/banknote.svg';
import { ReactComponent as Bell } from 'assets/icons/bell.svg';
import { ReactComponent as Bookmark } from 'assets/icons/bookmark.svg';
import { ReactComponent as Calendar } from 'assets/icons/calendar.svg';
import { ReactComponent as Camera } from 'assets/icons/camera.svg';
import { ReactComponent as ChatText } from 'assets/icons/chat-text.svg';
import { ReactComponent as CheckCircle } from 'assets/icons/check-circle.svg';
import { ReactComponent as ChevronDoubleDown } from 'assets/icons/chevron-double-down.svg';
import { ReactComponent as ChevronDoubleLeft } from 'assets/icons/chevron-double-left.svg';
import { ReactComponent as ChevronDoubleRight } from 'assets/icons/chevron-double-right.svg';
import { ReactComponent as ChevronDoubleUp } from 'assets/icons/chevron-double-up.svg';
import { ReactComponent as ChevronDownCircle } from 'assets/icons/chevron-down-circle.svg';
import { ReactComponent as ChevronDown } from 'assets/icons/chevron-down.svg';
import { ReactComponent as ChevronLeftCircle } from 'assets/icons/chevron-left-circle.svg';
import { ReactComponent as ChevronLeft } from 'assets/icons/chevron-left.svg';
import { ReactComponent as ChevronRightCircle } from 'assets/icons/chevron-right-circle.svg';
import { ReactComponent as ChevronRight } from 'assets/icons/chevron-right.svg';
import { ReactComponent as ChevronUpCircle } from 'assets/icons/chevron-up-circle.svg';
import { ReactComponent as ChevronUp } from 'assets/icons/chevron-up.svg';
import { ReactComponent as CircleFilled } from 'assets/icons/circle-filled.svg';
import { ReactComponent as Circle } from 'assets/icons/circle.svg';
import { ReactComponent as Close } from 'assets/icons/close.svg';
import { ReactComponent as CoinDollar } from 'assets/icons/coin-dollar.svg';
import { ReactComponent as Cross } from 'assets/icons/cross.svg';
import { ReactComponent as Edit } from 'assets/icons/edit.svg';
import { ReactComponent as Export } from 'assets/icons/export.svg';
import { ReactComponent as ExternalLink } from 'assets/icons/external-link.svg';
import { ReactComponent as Eye } from 'assets/icons/eye.svg';
import { ReactComponent as Facebook } from 'assets/icons/facebook.svg';
import { ReactComponent as FileDownload } from 'assets/icons/file-download.svg';
import { ReactComponent as File } from 'assets/icons/file.svg';
import { ReactComponent as FolderArchive } from 'assets/icons/folder-archive.svg';
import { ReactComponent as Folder } from 'assets/icons/folder.svg';
import { ReactComponent as Heart } from 'assets/icons/heart.svg';
import { ReactComponent as Home } from 'assets/icons/home.svg';
import { ReactComponent as Image } from 'assets/icons/image.svg';
import { ReactComponent as Import } from 'assets/icons/import.svg';
import { ReactComponent as Info } from 'assets/icons/info.svg';
import { ReactComponent as Instagram } from 'assets/icons/instagram.svg';
import { ReactComponent as Laptop } from 'assets/icons/laptop.svg';
import { ReactComponent as LightBulb } from 'assets/icons/light-bulb.svg';
import { ReactComponent as LineUp } from 'assets/icons/line-up.svg';
import { ReactComponent as Link } from 'assets/icons/link.svg';
import { ReactComponent as Linkedin } from 'assets/icons/linkedin.svg';
import { ReactComponent as Location } from 'assets/icons/location.svg';
import { ReactComponent as LockOpen } from 'assets/icons/lock-open.svg';
import { ReactComponent as Lock } from 'assets/icons/lock.svg';
import { ReactComponent as Login } from 'assets/icons/login.svg';
import { ReactComponent as Logout } from 'assets/icons/logout.svg';
import { ReactComponent as Mail } from 'assets/icons/mail.svg';
import { ReactComponent as Map } from 'assets/icons/map.svg';
import { ReactComponent as Megaphone } from 'assets/icons/megaphone.svg';
import { ReactComponent as Menu } from 'assets/icons/menu.svg';
import { ReactComponent as Minus } from 'assets/icons/minus.svg';
import { ReactComponent as Mobile } from 'assets/icons/mobile.svg';
import { ReactComponent as More } from 'assets/icons/more.svg';
import { ReactComponent as Pause } from 'assets/icons/pause.svg';
import { ReactComponent as Phone } from 'assets/icons/phone.svg';
import { ReactComponent as Pinterest } from 'assets/icons/pinterest.svg';
import { ReactComponent as PlayCircle } from 'assets/icons/play-circle.svg';
import { ReactComponent as Play } from 'assets/icons/play.svg';
import { ReactComponent as Plus } from 'assets/icons/plus.svg';
import { ReactComponent as QuestionMark } from 'assets/icons/question-mark.svg';
import { ReactComponent as Remove } from 'assets/icons/remove.svg';
import { ReactComponent as RoundOutline } from 'assets/icons/round-outline.svg';
import { ReactComponent as RoundSolid } from 'assets/icons/round-solid.svg';
import { ReactComponent as Search } from 'assets/icons/search.svg';
import { ReactComponent as Send } from 'assets/icons/send.svg';
import { ReactComponent as SettingsAdjust } from 'assets/icons/settings-adjust.svg';
import { ReactComponent as Settings } from 'assets/icons/settings.svg';
import { ReactComponent as Share } from 'assets/icons/share.svg';
import { ReactComponent as ShoppingCart } from 'assets/icons/shopping-cart.svg';
import { ReactComponent as SquareChecked } from 'assets/icons/square-checked.svg';
import { ReactComponent as Square } from 'assets/icons/square.svg';
import { ReactComponent as Star } from 'assets/icons/star.svg';
import { ReactComponent as StarFlame } from 'assets/icons/star-flame.svg';
import { ReactComponent as Time } from 'assets/icons/time.svg';
import { ReactComponent as TrashCan } from 'assets/icons/trash-can.svg';
import { ReactComponent as Trophy } from 'assets/icons/trophy.svg';
import { ReactComponent as Twitter } from 'assets/icons/twitter.svg';
import { ReactComponent as UserCircle } from 'assets/icons/user-circle.svg';
import { ReactComponent as User } from 'assets/icons/user.svg';
import { ReactComponent as Warning } from 'assets/icons/warning.svg';
import { ReactComponent as Youtube } from 'assets/icons/youtube.svg';

export type IconString =
    | 'adjust'
    | 'arrow-down-circle'
    | 'arrow-down'
    | 'arrow-left-circle'
    | 'arrow-left'
    | 'arrow-right-circle'
    | 'arrow-right'
    | 'arrow-up-circle'
    | 'arrow-up'
    | 'at'
    | 'bank-card'
    | 'bank'
    | 'banknote'
    | 'bell'
    | 'bookmark'
    | 'calendar'
    | 'camera'
    | 'chat-text'
    | 'check-circle'
    | 'chevron-double-down'
    | 'chevron-double-left'
    | 'chevron-double-right'
    | 'chevron-double-up'
    | 'chevron-down-circle'
    | 'chevron-down'
    | 'chevron-left-circle'
    | 'chevron-left'
    | 'chevron-right-circle'
    | 'chevron-right'
    | 'chevron-up-circle'
    | 'chevron-up'
    | 'circle-filled'
    | 'circle'
    | 'close'
    | 'coin-dollar'
    | 'cross'
    | 'edit'
    | 'export'
    | 'external-link'
    | 'eye'
    | 'facebook'
    | 'file-download'
    | 'file'
    | 'folder-archive'
    | 'folder'
    | 'heart'
    | 'home'
    | 'image'
    | 'import'
    | 'info'
    | 'instagram'
    | 'laptop'
    | 'light-bulb'
    | 'line-up'
    | 'link'
    | 'linkedin'
    | 'location'
    | 'lock-open'
    | 'lock'
    | 'login'
    | 'logout'
    | 'mail'
    | 'map'
    | 'megaphone'
    | 'menu'
    | 'minus'
    | 'mobile'
    | 'more'
    | 'pause'
    | 'phone'
    | 'pinterest'
    | 'play-circle'
    | 'play'
    | 'plus'
    | 'question-mark'
    | 'remove'
    | 'round-outline'
    | 'round-solid'
    | 'search'
    | 'send'
    | 'settings-adjust'
    | 'settings'
    | 'share'
    | 'shopping-cart'
    | 'square-checked'
    | 'square'
    | 'star'
    | 'star-flame'
    | 'time'
    | 'trash-can'
    | 'trophy'
    | 'twitter'
    | 'user-circle'
    | 'user'
    | 'warning'
    | 'youtube';

export type IconSVG = React.FunctionComponent<React.SVGProps<SVGSVGElement>>;

export const allIconComponents: Record<IconString, IconSVG> = {
    adjust: Adjust,
    'arrow-down-circle': ArrowDownCircle,
    'arrow-down': ArrowDown,
    'arrow-left-circle': ArrowLeftCircle,
    'arrow-left': ArrowLeft,
    'arrow-right-circle': ArrowRightCircle,
    'arrow-right': ArrowRight,
    'arrow-up-circle': ArrowUpCircle,
    'arrow-up': ArrowUp,
    at: At,
    'bank-card': BankCard,
    bank: Bank,
    banknote: Banknote,
    bell: Bell,
    bookmark: Bookmark,
    calendar: Calendar,
    camera: Camera,
    'chat-text': ChatText,
    'check-circle': CheckCircle,
    'chevron-double-down': ChevronDoubleDown,
    'chevron-double-left': ChevronDoubleLeft,
    'chevron-double-right': ChevronDoubleRight,
    'chevron-double-up': ChevronDoubleUp,
    'chevron-down-circle': ChevronDownCircle,
    'chevron-down': ChevronDown,
    'chevron-left-circle': ChevronLeftCircle,
    'chevron-left': ChevronLeft,
    'chevron-right-circle': ChevronRightCircle,
    'chevron-right': ChevronRight,
    'chevron-up-circle': ChevronUpCircle,
    'chevron-up': ChevronUp,
    'circle-filled': CircleFilled,
    circle: Circle,
    close: Close,
    'coin-dollar': CoinDollar,
    cross: Cross,
    edit: Edit,
    export: Export,
    'external-link': ExternalLink,
    eye: Eye,
    facebook: Facebook,
    'file-download': FileDownload,
    file: File,
    'folder-archive': FolderArchive,
    folder: Folder,
    heart: Heart,
    home: Home,
    image: Image,
    import: Import,
    info: Info,
    instagram: Instagram,
    laptop: Laptop,
    'light-bulb': LightBulb,
    'line-up': LineUp,
    link: Link,
    linkedin: Linkedin,
    location: Location,
    'lock-open': LockOpen,
    lock: Lock,
    login: Login,
    logout: Logout,
    mail: Mail,
    map: Map,
    megaphone: Megaphone,
    menu: Menu,
    minus: Minus,
    mobile: Mobile,
    more: More,
    pause: Pause,
    phone: Phone,
    pinterest: Pinterest,
    'play-circle': PlayCircle,
    play: Play,
    plus: Plus,
    'question-mark': QuestionMark,
    remove: Remove,
    'round-outline': RoundOutline,
    'round-solid': RoundSolid,
    search: Search,
    send: Send,
    'settings-adjust': SettingsAdjust,
    settings: Settings,
    share: Share,
    'shopping-cart': ShoppingCart,
    'square-checked': SquareChecked,
    square: Square,
    star: Star,
    'star-flame': StarFlame,
    time: Time,
    'trash-can': TrashCan,
    trophy: Trophy,
    twitter: Twitter,
    'user-circle': UserCircle,
    user: User,
    warning: Warning,
    youtube: Youtube
};

interface IconProps {
    icon: IconString | string;
    className?: string;
    // eslint-disable-next-line no-unused-vars
    onClick?: (event: ClickOrPressEvent) => void;
}

const Icon = (props: IconProps) => {
    const { icon, className, onClick } = props;
    const iconName = kebabCase(icon) as IconString;
    const SpecificIcon = allIconComponents[iconName];

    if (!SpecificIcon) return null;

    const isClickableClass = onClick ? 'svg-icon-clickable' : '';

    const iconClass = `svg-icon ${isClickableClass} ${className ? className : ''}`;

    return <SpecificIcon onClick={onClick} className={iconClass} />;
};

export default Icon;
