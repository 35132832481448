import React, { FunctionComponent, useState, useEffect, useCallback } from 'react';
import ReactDOM from 'react-dom';
import { v4 as uuidv4 } from 'uuid';
import { RenderWithPropsFn } from 'utils/renderListWithDataProps';
import Pagination from '4-sections/Pagination/Pagination';
import LoadingIndicator from '3-components/LoadingIndicator/LoadingIndicator';
import { getStarsList, getStar } from '3-components/Utils/Services';
import { formatToAUCurrency, formatHugeAmountToAUCurrency, formatToAUInt } from '3-components/Utils/NumberUtils';
import { StoryItem } from '../../@types/storyItem';
import { DonationItem } from '../../@types/donationItem';
import DonationListItemStory from './DonationListItemStory';
import DonationListItemDonation from './DonationListItemDonation';
import DonationListItemMyStar from './DonationListItemMyStar';
import { STAR_ID_KEY } from '../../constants/starIdKey';

export interface DonationListProps {
    stories?: [StoryItem];
    donations?: [DonationItem];
    items? : [StoryItem | DonationItem];
}

const DonationList: FunctionComponent<DonationListProps> = props => {
    const {
        stories,
        donations,
        items
    } = props;

    const [isLoading, setIsLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(10);
    const [totalItems, setTotalItems] = useState((stories?.length || 0) + (donations?.length || 0));
    const [starFilter, setStarFilter] = useState('all');
    const [displayedDonations, setDisplayedDonations] = useState(items);
    const urlParams = new URLSearchParams(window.location.search);
    const [starId] = useState(urlParams.get(STAR_ID_KEY));
    const [myStar, setMyStar] = useState({ id: '', name: '', dedication: '', state: null, total: 0, timestamp: '' });
    const [attempt, setAttempt] = useState(1);
    const [isFirstLoad, setIsFirstLoad] = useState(true);
    const [shouldFocusOnFirstItem, setShouldFocusOnFirstItem] = useState(false);

    const focusToFirst = useCallback(() => {
        if (!isFirstLoad) {
            setTimeout(() => {
                const firstItem = document.querySelector('.donation-list-container li');
                if (firstItem) {
                    firstItem.setAttribute('tabindex', '-1');
                    (firstItem as HTMLElement).focus();
                }
            }, 0);
        }
        setShouldFocusOnFirstItem(false);
    }, [isFirstLoad]);
    useEffect(function focusFirstItemOnListChange() {
        if (shouldFocusOnFirstItem) focusToFirst();
    }, [shouldFocusOnFirstItem, focusToFirst]);

    const retrieveList = useCallback((newPage?: number, filter?: string) => {
        const abortController = new AbortController();
        const param = { pageNumber: newPage ? newPage : 1 };

        const paramFilter = filter ? filter : starFilter;
        if (paramFilter === 'stories') {
            setIsLoading(true);
            setTimeout(() => {
                setCurrentPage(param.pageNumber);
                setIsLoading(false);
                setShouldFocusOnFirstItem(true);
            }, 500);
        }

        if (param.pageNumber <= totalPages) {
            setIsLoading(true);
            const getData = async function () {
                try {
                    const data = await getStarsList(param);
                    if (data.donations.items) {
                        setDisplayedDonations(data.donations.items);
                    }
                    if (param.pageNumber) {
                        setCurrentPage(param.pageNumber);
                    }
                    if (data.donations.totalPages) {
                        setTotalPages(data.donations.totalPages);
                    }
                    if (data.itemsPerPage) {
                        setItemsPerPage(data.donations.itemsPerPage);
                    }
                    if (data.donations.totalItems) {
                        setTotalItems(data.donations.totalItems);
                    }
                    setIsLoading(false);

                    const donationTargetTitle = document.querySelector('.donation-target-title');
                    if (donationTargetTitle) {
                        let totalToDisplay = formatToAUCurrency(data.totalDonationAmount);
                        if (data.totalDonationAmount / 1000000000 >= 1) {
                            totalToDisplay = formatHugeAmountToAUCurrency(data.totalDonationAmount);
                        }

                        donationTargetTitle.innerHTML = totalToDisplay;
                    }
                    const donationTargetSubtitle = document.querySelector('.donation-target-subtitle');
                    if (donationTargetSubtitle) {
                        const donorsCount = formatToAUInt(data.donations.totalItems);
                        donationTargetSubtitle.innerHTML = `Raised by ${donorsCount} donors`;
                    }
                } catch (error) {
                    console.error(error);
                }

                setShouldFocusOnFirstItem(true);
            };

            setTimeout(() => {
                getData();
            }, 500);
        } else {
            // just retrieve the rest of the stories.
            setIsLoading(true);
            setTimeout(() => {
                if (param.pageNumber) {
                    setCurrentPage(param.pageNumber);
                }
                setIsLoading(false);
                setShouldFocusOnFirstItem(true);
            }, 500);
        }

        return () => abortController.abort();
    }, [starFilter, totalPages]);

    const retrieveStar = useCallback(() => {
        const abortController = new AbortController();

        const failHandler = () => {
            if (attempt < 4) {
                setTimeout(() => {
                    setAttempt(attempt + 1);
                }, 2500);
            } else {
                setIsLoading(false);
            }
        };

        const getData = async function () {
            try {
                setIsLoading(true);
                let paramToSend = starId;
                if (paramToSend === 'delayed') {
                    paramToSend = `${attempt}`;
                }
                const data = await getStar(`${paramToSend}`);
                if (data) {
                    if (data.name && data.total) {
                        const pageIntro = document.querySelector('.personal-intro');
                        if (pageIntro) {
                            pageIntro.textContent = `We're brighter together, ${data.name}`;
                        }

                        setMyStar(data);
                    }
                    setIsLoading(false);
                } else {
                    failHandler();
                }
            } catch (error) {
                console.error(error);
                failHandler();
            }
        };

        setTimeout(() => {
            getData();
        }, 500);

        return () => abortController.abort();
    }, [attempt, starId]);

    const changePage = (newPage: number) => {
        setCurrentPage(newPage);
        if (isFirstLoad) {
            setIsFirstLoad(false);
        }
        retrieveList(newPage);
    };

    const updateFilter = useCallback((newFilter?: string) => {
        const filter = newFilter ? newFilter : 'all';
        setStarFilter(filter);
        if (isFirstLoad) {
            setIsFirstLoad(false);
        }
        retrieveList(1, filter);
    }, [retrieveList, isFirstLoad]);

    useEffect(() => {
        window.updateDonationFilter = filter => {
            updateFilter(filter);
        };
        retrieveList();
    }, [retrieveList, updateFilter]);

    useEffect(() => {
        if (starId) {
            retrieveStar();
        }
    }, [retrieveStar, starId]);

    const hideStory = starFilter === 'donations';
    const hideDonation = starFilter === 'stories';

    const idealStoryPerPage = 2;
    const maxPageWithStory = Math.ceil((stories?.length || 0) / 2);

    const getStoriesLeftAfterLastPage = () => {
        const itemsOnLastPage = totalItems % itemsPerPage === 0 ? itemsPerPage : (totalItems % itemsPerPage);
        const maxStoriesOnLastPage = (itemsPerPage + idealStoryPerPage) - itemsOnLastPage;
        const storiesLeftAfterLastPage = (stories?.length || 0) - (((totalPages - 1) * idealStoryPerPage) + maxStoriesOnLastPage);
        return storiesLeftAfterLastPage;
    };

    const getDisplayedStories = () => {
        if (starFilter === 'stories') {
            const sliceStart = (currentPage - 1) * itemsPerPage;
            const sliceEnd = sliceStart + itemsPerPage;
            return stories?.slice(sliceStart, sliceEnd);
        }

        if (displayedDonations) {
            if (currentPage <= totalPages && currentPage <= maxPageWithStory) {
                // show story, but how much?
                const sliceStart = (currentPage - 1) * idealStoryPerPage;
                if (displayedDonations.length < itemsPerPage) {
                    // show more than two stories, up to items per page + idealStoryPerPage
                    const sliceEnd = (sliceStart + itemsPerPage + idealStoryPerPage - displayedDonations.length);
                    return stories?.slice(sliceStart, sliceEnd);
                }
                // show one - two stories, but which one?
                const sliceEnd = (sliceStart + 2);
                return stories?.slice(sliceStart, sliceEnd);
            } if (currentPage > totalPages) {
                const howMuchLeft = getStoriesLeftAfterLastPage();
                return stories?.slice(-howMuchLeft);
            }
        }

        return [];
    };

    const storiesToShow = getDisplayedStories();

    const getPageModifier = () => {
        if (starFilter === 'all') {
            if (totalPages < maxPageWithStory) {
                const howMuchLeft = getStoriesLeftAfterLastPage();
                if (howMuchLeft > 0) {
                    return 1;
                }
            }
        } else if (starFilter === 'stories') {
            return Math.ceil((stories?.length || 0) / itemsPerPage);
        }
        return 0;
    };

    const calculatedTotalPage = (starFilter === 'stories' ? 0 : totalPages) + getPageModifier();
    const previousPagesItemsSum = (currentPage - 1) * (itemsPerPage + idealStoryPerPage);
    const thisPageItemsSum = previousPagesItemsSum + (hideStory ? 0 : (storiesToShow?.length || 0))
                                + (hideDonation || currentPage > totalPages ? 0 : (displayedDonations?.length || 0));
    const totalAllItems = (hideStory ? 0 : (stories?.length || 0))
                                + (hideDonation ? 0 : (totalItems));

    return (
        <>
            {isLoading && (
                <>
                    <LoadingIndicator loadingText="Fetching data..." />
                </>
            )}
            {!isLoading && (
                <>
                    <ul className="donation-list-container">
                        {!hideDonation
                            && currentPage === 1
                            && myStar.id
                            && (<DonationListItemMyStar {...myStar} />)}
                        {!hideStory && storiesToShow?.map(story => (<DonationListItemStory key={uuidv4()} {...story} />))}
                        {!hideDonation
                            && currentPage <= totalPages
                            && displayedDonations?.map(donation => (
                                <DonationListItemDonation
                                    key={uuidv4()}
                                    {...(donation as DonationItem)}
                                />
                            ))}
                    </ul>
                    {calculatedTotalPage > 1 && (
                        <Pagination
                            currentPage={currentPage}
                            totalPage={calculatedTotalPage}
                            pageItemStart={previousPagesItemsSum + 1}
                            pageItemEnd={thisPageItemsSum}
                            totalItems={totalAllItems}
                            paginationHandle={changePage}
                        />
                    )}
                </>
            )}
        </>
    );
};

const DonationListAppInit: RenderWithPropsFn<DonationListProps> = ({ el, props }) => {
    ReactDOM.render(
        <DonationList {...props} />, el
    );
};

export { DonationListAppInit as default };
