import React, { FunctionComponent, useState } from 'react';
import Modal from '3-components/Modal/ModalReact';
import StoryModalBlock from '4-sections/StoryModalBlock/StoryModalBlock';
import { StoryItem } from '../../@types/storyItem';

const DonationListItemStory: FunctionComponent<StoryItem> = props => {
    const {
        // id,
        title,
        starImageUrl,
        storyImageUrl,
        shortStory,
        fullStory,
        storyUrl,
        ctaTitle
    } = props;

    const [modalOpened, setModalOpened] = useState(false);

    const modalOpenHandle = () => {
        setModalOpened(true);
        toggleAriaHidden(true);
    };

    const modalCloseHandle = () => {
        setModalOpened(false);
        toggleAriaHidden(false);
    };

    const toggleAriaHidden = (incomingOpenState: boolean) => {
        const headers = document.querySelectorAll('header');
        headers.forEach(headerElement => {
            if (incomingOpenState) {
                headerElement.setAttribute('aria-hidden', 'true');
            } else {
                headerElement.removeAttribute('aria-hidden');
            }
        });

        const pageContent = document.querySelector('#page-content');
        if (pageContent) {
            if (incomingOpenState) {
                pageContent.setAttribute('aria-hidden', 'true');
            } else {
                pageContent.removeAttribute('aria-hidden');
            }
        }
    };

    return (
        <>
            <li className="donation-list-item-story">
                <span>
                    <button type="button" className="star-button" onClick={modalOpenHandle}>
                        <img className="donation-list-image" src={starImageUrl} alt={`Story Star - ${title}`} />
                    </button>
                </span>
                <div className="donation-list-intro">
                    <h3 className="intro-title">{title}</h3>
                    <p className="intro-short-desc">{shortStory}</p>
                    {fullStory && (
                        <button
                            type="button"
                            className="story-open-button"
                            onClick={modalOpenHandle}
                            aria-label={`Read ${title}`}
                        >
                            Read more
                        </button>
                    )}
                </div>
            </li>
            <Modal
                isOpen={modalOpened}
                onCloseClick={modalCloseHandle}
                ariaLabel={title}
                modalTitle=""
                additionalClasses="story-modal"
                closeButtonPrefixText="Back to Stars"
            >
                <StoryModalBlock
                    title={title}
                    story={fullStory}
                    storyUrl={storyUrl}
                    cover={storyImageUrl}
                    ctaTitle={ctaTitle}
                />
            </Modal>
        </>
    );
};

export default DonationListItemStory;
