import React, { FunctionComponent } from 'react';
import SocialShare from '3-components/SocialShare/SocialShareReact';
import TimeSinceNow from '3-components/Utils/DateUtils';
import { createFacebookShareUrl, createTwitterShareUrl, createEmailShareUrl } from '3-components/Utils/ShareUtils';
import { DonationItem } from '../../@types/donationItem';

const DonationListItemMyStar: FunctionComponent<DonationItem> = props => {
    const {
        id,
        dedication,
        total,
        timestamp
    } = props;

    const mystarUrl = `https://${window.location.host}/view?star=${id}`;
    const facebookShareUrl = createFacebookShareUrl(mystarUrl);
    const twitterShareUrl = createTwitterShareUrl(mystarUrl);
    const emailShareUrl = createEmailShareUrl(mystarUrl);

    const donateTime = TimeSinceNow(timestamp);

    return (
        <li className="donation-list-item-mystar">
            <div className="mystar-item-content">
                <img className="donation-list-image" src="/assets/images/Star-White.png" alt="my star" />
                <div className="donation-list-info">
                    <div className="donation-list-intro">
                        <h3 className="intro-title">
                            My star
                        </h3>
                        <p className="intro-short-desc">{dedication}</p>
                        <SocialShare
                            intro={true}
                            facebookLink={facebookShareUrl}
                            twitterLink={twitterShareUrl}
                            emailLink={emailShareUrl}
                            copyLink={mystarUrl}
                            additionalClasses="mystar-share mobile-hide"
                        />
                    </div>
                    <div className="donation-amount">
                        <span className="donation-total">
                            ${total} donation
                        </span>
                        <span>{donateTime}</span>
                    </div>
                </div>
            </div>
            <SocialShare
                intro={true}
                facebookLink={facebookShareUrl}
                twitterLink={twitterShareUrl}
                emailLink={emailShareUrl}
                copyLink={mystarUrl}
                additionalClasses="mystar-share desktop-hide"
            />
        </li>
    );
};

export default DonationListItemMyStar;
